/* Contenedor principal */
.container {
    max-width: 1200px;
    margin: auto;
    padding: 1rem;
}

/* Columnas */
.row > .col-md-3 {
    padding-right: 1rem;
}

.row > .col-md-9 {
    padding-left: 1rem;
}

/* Carrito y acordeón */
.carrito-container,
.acordeon-container {
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    margin-bottom: 1rem;
}

/* Botones de incrementar y decrementar */
.increment-button,
.decrement-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 0.25rem;
    font-size: 0.8rem;
    padding: 0.2rem 0.5rem;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.increment-button:hover,
.decrement-button:hover {
    background-color: #0056b3;
}

.increment-button:disabled,
.decrement-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Etiqueta de cantidad */
.quantity-label {
    font-weight: bold;
    font-size: 1rem;
    margin: 0 0.5rem;
}

/* Categorías y tags */
.categoria-filtro-container {
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
}

/* Botón borrar filtros */
.clear-filters-btn {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 10;
    padding: 0.2rem 0.5rem;
    font-size: 0.8rem;
}

/* Listado general */
.categoria-filtro-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.categoria-filtro-item {
    margin-bottom: 1rem;
}

/* Botones de categorías */
.categoria-button {
    width: 100%;
    text-align: left;
    background: none;
    border: none;
    color: #333;
    font-size: 0.9rem;
    font-weight: bold; /* Categorías en negrita */
    padding: 0.2rem 0;
    cursor: pointer;
    transition: color 0.2s, text-decoration 0.2s;
}

.categoria-button:hover {
    text-decoration: underline;
    color: #007bff;
}

.categoria-button.active {
    color: #007bff;
}

/* Tags */
.tag-list {
    list-style: none;
    padding: 0;
    margin: 0.5rem 0 0 1rem;
}

.tag-item {
    margin-bottom: 0.5rem;
}

/* Botones de tags */
.tag-button {
    background: none;
    border: none;
    color: #666;
    font-size: 0.85rem;
    padding: 0;
    cursor: pointer;
    transition: color 0.2s, text-decoration 0.2s;
}

.tag-button:hover {
    text-decoration: underline;
    color: #007bff;
}

.tag-button.active {
    font-weight: bold;
    color: #007bff;
}

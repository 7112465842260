/* Index.css */

/* Estilos generales */
.index-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    padding: 0;
    background-color: #50745f;
  }
  
  /* Logo */
  .index-logo {
    margin-bottom: 1.5rem;
    max-width: 150px;
  }
  
  /* Slogan */
  .index-slogan {
    text-align: center;
    color: white;
  }
  
  /* Subtítulo */
  .index-subtitle {
    text-align: center;
    margin-top: 1rem;
    color: #f8f9fa;
  }
  
  /* Contenedor de botones */
  .index-button-container {
    display: flex;
    gap: 1rem;
  }
  
  /* Botón de inicio de sesión */
  .index-button {
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    font-weight: bold;
  }
  
  .index-button-primary {
    background-color: white;
    border: none;
    color: #000;
  }
  
  .index-button-primary:hover {
    background-color: #f0f0f0;
  }
  
  .index-button-secondary {
    background-color: transparent;
    border: 1px solid white;
    color: white;
  }
  
  .index-button-secondary:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
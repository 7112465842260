/* Carrito.css */

.carrito {
    background-color: #f4f4f4;
    border-radius: 10px;
    padding: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    max-width: 450px;
    margin: 20px auto;
    font-family: 'Arial', sans-serif;
}

.carrito h2 {
    font-size: 1.4rem;
    color: #222;
    font-weight: bold;
    text-align: center;
    margin-bottom: 16px;
}

.carrito .list-group-item {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 12px;
    display: flex;
    align-items: center;
}

.carrito .list-group-item h5 {
    font-size: 1rem;
    color: #333;
    margin-bottom: 4px;
}

.carrito .list-group-item p {
    font-size: 0.85rem;
    color: #555;
    margin-bottom: 2px;
}

.carrito .badge {
    font-size: 0.9rem;
    padding: 6px 10px;
    border-radius: 8px;
    font-weight: normal;
}

.carrito .badge.bg-primary {
    background-color: #0056b3;
    color: #ffffff;
}

.carrito .badge.bg-success {
    background-color: #198754;
    color: #ffffff;
}

.carrito .btn {
    padding: 12px 0;
    font-size: 0.9rem;
    font-weight: bold;
    border-radius: 6px;
    transition: background-color 0.2s ease-in-out, transform 0.2s ease;
    width: 100%;
    text-align: center;
}

.carrito .d-flex {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.carrito .btn-primary {
    background-color: #0056b3;
    color: white;
    border: none;
    height: 48px;
}

.carrito .btn-primary:hover {
    background-color: #003f8a;
    transform: translateY(-2px);
}

.carrito .btn-warning {
    background-color: #f39c12;
    color: white;
    border: none;
    height: 48px;
}

.carrito .btn-warning:hover {
    background-color: #d98307;
    transform: translateY(-2px);
}

.carrito .alert {
    background-color: #f8d7da;
    color: #842029;
    padding: 12px;
    border-radius: 8px;
    text-align: center;
    font-size: 0.85rem;
}

.carrito .list-group {
    list-style: none;
    padding: 0;
    margin: 0;
}

.carrito .total {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    padding: 12px;
    background-color: #f4f4f4;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.carrito .col-info {
    flex-grow: 1;
    padding-right: 15px;
}

.carrito .col-cantidad {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-width: 120px;
}

.carrito .col-cantidad .badge {
    margin-top: 10px;
}
